//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isValidLATLONG } from '@/utils/util';
import { defaultLanguages, defaultInformation, initNaviPoint } from './types';

export default {
  components: {
    MapModal: () => import('./modals/Map')
  },
  props: {
    languages: {
      type: Array,
      required: true
    },
    points: {
      type: Array,
      required: true
    },
    codeStatus: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: null,
      visibleMap: false,
      currentLocation: {},
      pointKey: '',
      isValidLAT: {},
      isValidLONG: {}
    };
  },
  created() {
    this.form = this.$form.createForm(this);
  },
  computed: {
    mappingLangToInfo() {
      return this.languages.map(item => `INFO_${item}`);
    },
    editColumns() {
      const editNames = defaultLanguages.filter(value => this.languages.includes(value));
      const editInfors = defaultInformation.filter(value => this.mappingLangToInfo.includes(value));
      return editNames.concat(editInfors).concat('LAT', 'LONG', 'MAP');
    },
    JPNPointName() {
      return {
        title: this.$t('JPN'),
        key: 'JPN',
        dataIndex: 'JPN',
        width: 250,
        align: 'center',
        scopedSlots: { customRender: 'JPN' }
      };
    },
    ENGPointName() {
      return {
        title: this.$t('ENG'),
        key: 'ENG',
        dataIndex: 'ENG',
        width: 250,
        align: 'center',
        scopedSlots: { customRender: 'ENG' }
      };
    },
    KORPointName() {
      return {
        title: this.$t('KOR'),
        key: 'KOR',
        dataIndex: 'KOR',
        width: 250,
        align: 'center',
        scopedSlots: { customRender: 'KOR' }
      };
    },
    CHIPointName() {
      return {
        title: this.$t('CHI'),
        key: 'CHI',
        dataIndex: 'CHI',
        width: 250,
        align: 'center',
        scopedSlots: { customRender: 'CHI' }
      };
    },
    JPNInfor() {
      return {
        title: this.$t('JPN'),
        key: 'INFO_JPN',
        dataIndex: 'INFO_JPN',
        width: 200,
        align: 'center',
        scopedSlots: { customRender: 'INFO_JPN' }
      };
    },
    ENGInfor() {
      return {
        title: this.$t('ENG'),
        key: 'INFO_ENG',
        dataIndex: 'INFO_ENG',
        width: 200,
        align: 'center',
        scopedSlots: { customRender: 'INFO_ENG' }
      };
    },
    KORInfor() {
      return {
        title: this.$t('KOR'),
        key: 'INFO_KOR',
        dataIndex: 'INFO_KOR',
        width: 200,
        align: 'center',
        scopedSlots: { customRender: 'INFO_KOR' }
      };
    },
    CHIInfor() {
      return {
        title: this.$t('CHI'),
        key: 'INFO_CHI',
        dataIndex: 'INFO_CHI',
        width: 200,
        align: 'center',
        scopedSlots: { customRender: 'INFO_CHI' }
      };
    },
    pointName() {
      return {
        title: this.$t('Point Name'),
        align: 'center',
        children: []
      };
    },
    location() {
      return {
        title: this.$t('Point Location'),
        children: [
          {
            title: this.$t('LAT'),
            key: 'LAT',
            dataIndex: 'LAT',
            width: 180,
            align: 'center',
            scopedSlots: { customRender: 'LAT' }
          },
          {
            title: this.$t('LONG'),
            key: 'LONG',
            dataIndex: 'LONG',
            width: 180,
            align: 'center',
            scopedSlots: { customRender: 'LONG' }
          },
          {
            key: 'MAP',
            width: 60,
            align: 'center',
            scopedSlots: { customRender: 'MAP' }
          }
        ]
      };
    },
    descriptions() {
      return {
        title: this.$t('Information'),
        align: 'center',
        children: []
      };
    },
    actions() {
      return {
        title: this.$t('Point Action'),
        dataIndex: 'operation',
        fixed: 'right',
        width: 120,
        align: 'center',
        scopedSlots: { customRender: 'operation' }
      };
    },
    columns() {
      let columns = [];
      let pointNames = [];
      let infos = [];

      this.languages.forEach(item => {
        if (item === 'JPN') {
          pointNames = [...pointNames, this.JPNPointName];
          infos = [...infos, this.JPNInfor];
        }
        if (item === 'ENG') {
          pointNames = [...pointNames, this.ENGPointName];
          infos = [...infos, this.ENGInfor];
        }
        if (item === 'KOR') {
          pointNames = [...pointNames, this.KORPointName];
          infos = [...infos, this.KORInfor];
        }
        if (item === 'CHI') {
          pointNames = [...pointNames, this.CHIPointName];
          infos = [...infos, this.CHIInfor];
        }
      });

      if (pointNames.length > 0) {
        columns = [...columns, { ...this.pointName, children: pointNames }];
      }
      columns = [...columns, this.location];
      if (infos.length > 0) {
        columns = [...columns, { ...this.descriptions, children: infos }];
      }
      columns = [...columns, this.actions];

      return columns;
    },
    canPreview() {
      return this.points?.length > 0;
    },
    requiredMessage() {
      return {
        JPN: this.$t('Please input point name in japanese'),
        ENG: this.$t('Please input point name in english'),
        CHI: this.$t('Please input point name in chinese'),
        KOR: this.$t('Please input point name in korean'),
        LAT: this.$t('Please input your latitude'),
        LONG: this.$t('Please input your longitude')
      };
    },
    errorMessage() {
      return {
        LAT: this.$t('Latitude is invalid'),
        LONG: this.$t('Longitude is invalid')
      };
    }
  },
  methods: {
    addPoint() {
      const key = this.points.length + 1;
      const point = { ...initNaviPoint, key };
      const newPoints = [...this.points, point];
      this.$emit('setPoints', newPoints);
    },

    inputChange(value, key, col) {
      const newPoints = [...this.points];
      const point = newPoints.filter(item => item.key === key)[0];
      if (point) {
        if (col === 'LAT') {
          this.isValidLAT = { ...this.isValidLAT, [key]: isValidLATLONG(value) };
        }
        if (col === 'LONG') {
          this.isValidLONG = { ...this.isValidLONG, [key]: isValidLATLONG(value) };
        }
        point[col] = value;
        this.$emit('setPoints', newPoints);
      }
    },
    removePoint(pointKey) {
      const newPoints = this.points.filter(item => item.key !== pointKey);
      this.$emit('setPoints', newPoints);
    },
    showModal(pointKey) {
      this.pointKey = pointKey;
      this.currentLocation = {
        lat: +this.points[pointKey]?.LAT,
        lng: +this.points[pointKey]?.LONG
      };
      this.showMapModal();
    },
    setLocation(location) {
      const newPoints = [...this.points];
      let point = newPoints.find(item => item.key === this.pointKey);
      if (point) {
        this.isValidLAT = { ...this.isValidLAT, [this.pointKey]: isValidLATLONG(location.lat) };
        this.isValidLONG = { ...this.isValidLONG, [this.pointKey]: isValidLATLONG(location.lng) };
        point.LAT = location.lat;
        point.LONG = location.lng;
        if (location.pointName) {
          this.languages.map(item => {
            point[item] = location.pointName;
          });
        }
        this.$emit('setPoints', newPoints);
      }
      this.closeMapModal();
    },
    showMapModal() {
      this.visibleMap = true;
    },
    closeMapModal() {
      this.visibleMap = false;
    }
  }
};
