var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form",
        { attrs: { form: _vm.form } },
        [
          _c("a-table", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.points,
              bordered: "",
              scroll: { x: "100%" },
              pagination: false,
              locale: {
                emptyText: _vm.$t("No data")
              }
            },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.editColumns, function(col, index) {
                  return {
                    key: col,
                    fn: function(text, record) {
                      return [
                        _c(
                          "div",
                          { key: index },
                          [
                            col === "MAP"
                              ? _c("a-button", {
                                  attrs: {
                                    icon: "environment",
                                    type: "primary"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.showModal(record.key)
                                    }
                                  }
                                })
                              : col === "LAT"
                              ? _c(
                                  "a-form-item",
                                  {
                                    staticClass: "py-none my-none",
                                    attrs: {
                                      "validate-status":
                                        !text ||
                                        _vm.isValidLAT[record.key] === false
                                          ? "error"
                                          : "",
                                      help:
                                        (!text
                                          ? _vm.requiredMessage.LAT
                                          : "") ||
                                        (_vm.isValidLAT[record.key] === false
                                          ? _vm.errorMessage.LAT
                                          : "")
                                    }
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: { value: text, type: "number" },
                                      on: {
                                        change: function(e) {
                                          return _vm.inputChange(
                                            e.target.value,
                                            record.key,
                                            col
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : col === "LONG"
                              ? _c(
                                  "a-form-item",
                                  {
                                    staticClass: "py-none my-none",
                                    attrs: {
                                      "validate-status":
                                        !text ||
                                        _vm.isValidLONG[record.key] === false
                                          ? "error"
                                          : "",
                                      help:
                                        (!text
                                          ? _vm.requiredMessage.LONG
                                          : "") ||
                                        (_vm.isValidLONG[record.key] === false
                                          ? _vm.errorMessage.LONG
                                          : "")
                                    }
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: { value: text, type: "number" },
                                      on: {
                                        change: function(e) {
                                          return _vm.inputChange(
                                            e.target.value,
                                            record.key,
                                            col
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c(
                                  "a-form-item",
                                  {
                                    staticClass: "py-none my-none",
                                    attrs: {
                                      "validate-status":
                                        !text && !col.includes("INFO")
                                          ? "error"
                                          : "",
                                      help:
                                        !text && !col.includes("INFO")
                                          ? _vm.requiredMessage[col]
                                          : ""
                                    }
                                  },
                                  [
                                    _c("a-input", {
                                      attrs: { value: text },
                                      on: {
                                        change: function(e) {
                                          return _vm.inputChange(
                                            e.target.value,
                                            record.key,
                                            col
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                          ],
                          1
                        )
                      ]
                    }
                  }
                }),
                {
                  key: "operation",
                  fn: function(text, record) {
                    return [
                      _c("div", { staticClass: "editable-row-operations" }, [
                        _c(
                          "a",
                          {
                            staticStyle: { color: "#e84118" },
                            on: {
                              click: function() {
                                return _vm.removePoint(record.key)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Remove")) + " ")]
                        )
                      ])
                    ]
                  }
                }
              ],
              null,
              true
            )
          }),
          _c(
            "a-row",
            { staticClass: "mt-sm", attrs: { type: "flex", justify: "end" } },
            [
              _c("a-button", { on: { click: _vm.addPoint } }, [
                _vm._v(" " + _vm._s(_vm.$t("Add Point")) + " ")
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm.visibleMap
        ? _c("map-modal", {
            attrs: { visible: _vm.visibleMap, location: _vm.currentLocation },
            on: { setLocation: _vm.setLocation, close: _vm.closeMapModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }